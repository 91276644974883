












































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import dayjs from "dayjs";
import * as echarts from "echarts";
import vueSeamless from "vue-seamless-scroll";
import countTo from "vue-count-to";
import api from "@/api";
import axios from "axios";
import { ExportExcel } from '../utils/filter'

@Component({
  components: {
    vueSeamless,
    countTo,
  },
})
export default class Home extends Vue {
  time = "-";
  noAuth = false;

  system = sessionStorage.getItem("system");
  checked = false

  tableLoading = false
  showType = true
  text = '切换图表'
  submit_time1 = ''
  submit_time2 = ''
  dateType = [
    {code: 'month', name: '月'},
    {code: 'day', name: '日'},
    {code: 'time', name: '小时'},
  ]
  type = 'year'
  format = 'yyyy'
  valueformat = 'yyyy'
  dateTypeVal = ''
  formInline: any = {
    submit_time: [],
    province_code: [],
    trading_center_code: [],
    sending_organization_code: [],
    show_empty: 0
  }
  pickerMinDate: any
  pickerOptions = {}
  baobiaoDialog = false
  baobiao(type: any) {
    if(this.system != '17') {
      return
    }
    let nowDate = new Date();
    let cloneNowDate = new Date();
    let fullYear = nowDate.getFullYear();
    let month = nowDate.getMonth() + 1; // getMonth 方法返回 0-11，代表1-12月
    let day = nowDate.getDate()
    let endOfMonth = new Date(fullYear, month, 0).getDate(); // 获取本月最后一天
    if(type == 1) {

      let endDate = this.getFullDate(cloneNowDate.setDate(day), 1);//当月最后一天
      let starDate = this.getFullDate(cloneNowDate.setDate(day), 1);//当月第一天
      this.dateTypeVal = 'time'
      this.type = 'date'
      this.format = 'yyyy-MM-dd'
      this.valueformat = 'yyyy-MM-dd'
      this.submit_time1 = starDate
      this.submit_time2 = endDate

      this.setpickerOptions(1)

    } else if(type == 2) {

      let endDate = this.getFullDate(cloneNowDate.setDate(endOfMonth), 2);//当月最后一天
      let starDate = this.getFullDate(cloneNowDate.setDate(1), 2);//当月第一天

      this.dateTypeVal = 'day'
      this.type = 'month'
      this.format = 'yyyy-MM'
      this.valueformat = 'yyyy-MM'
      this.submit_time1 = starDate
      this.submit_time2 = endDate

      this.setpickerOptions(2)

    } else if(type == 3) {
      this.dateTypeVal = 'month'
      this.type = 'year'
      this.format = 'yyyy'
      this.valueformat = 'yyyy'
      this.submit_time1 = fullYear.toString()
      this.submit_time2 = fullYear.toString()

      this.setpickerOptions(3)
    }
    this.getData()
    this.getDataEchart()
    this.baobiaoDialog = true
  }

  setpickerOptions(type: any) {
    if(type == 1) {
      this.pickerMinDate = new Date(this.submit_time1).getTime()
      this.pickerOptions = {
        disabledDate: time => {
          if (this.pickerMinDate) {
            const day1 = 3 * 24 * 3600 * 1000;
            let maxTime = this.pickerMinDate + day1 - (1 * 24 * 3600 * 1000);
            let minTime = this.pickerMinDate - day1;
            return time.getTime() > maxTime || time.getTime() < minTime;
          }
        }
      }

    } else if(type == 2) {
      this.pickerMinDate = this.submit_time1
      this.pickerOptions = {
        disabledDate: time => {
          if (this.pickerMinDate) {
            let date =new Date(this.pickerMinDate) //获取当前时间
            let year = date.getFullYear() //控制时间年
            let maxMonth =date.getMonth() + 2 //控制月份之前减、之后用加。获取3个月前的月份的index(0是1月)
            let minMonth =date.getMonth() - 2
            let day = date.getDate()
            let threeMounthsBeforeDay = (new Date(year,minMonth,day)).getTime() //定义2个月前的毫秒数
            let threeMounthsAfterDay = (new Date(year,maxMonth,day)).getTime() //定义2个月前的毫秒数
            //限制时间范围，如果要包含今天可以+/-8.64e7/8.64e6。现在是往后控制的时间，如果是往前控制 符号变成相反的就可以了
            return time.getTime() < threeMounthsBeforeDay || time.getTime() > threeMounthsAfterDay
          }
        }
      }
    } else if(type == 3) {
      this.pickerOptions = {
        disabledDate: time => {
          return false
        }
      }
    }
  }

  changeDate() {
    let a1 = new Date(this.submit_time1).getTime()
    let a2 = new Date(this.submit_time2).getTime()
    let a3: any
    if(a2 < a1) {
      a3 = this.submit_time2
      this.submit_time2 = this.submit_time1
      this.submit_time1 = a3
    }
  }

  setDisable() {
    if(this.dateTypeVal == 'time') {
      this.setpickerOptions(1)
    } else if(this.dateTypeVal == 'day') {
      this.setpickerOptions(2)
    } else {
      this.setpickerOptions(3)
    }

  }

  getFullDate(targetDate: any, type: any) {
    let D, y, m, d;
    D = new Date(targetDate);
    y = D.getFullYear();
    m = D.getMonth() + 1;
    d = D.getDate();
    m = m > 9 ? m : '0' + m;
    d = d > 9 ? d : '0' + d;
    //y + '-' + m + '-' + d
    let rtn = type == 1 ? y + '-' + m + '-' + d : y + '-' + m
    return rtn;

  }

  // 仅查看有地区数据
  handleCheckChange(e: any) {
    console.log(e);
    if(e) {
      this.formInline.show_empty = 1
    } else {
      this.formInline.show_empty = 0
    }
    this.getData()
    this.getDataEchart()
  }

  // 导出
  exportData() {
    this.tableLoading = true
    this.formInline.submit_time[0] = this.submit_time1
    this.formInline.submit_time[1] = this.submit_time2
    api
        .post('J22020', this.formInline)
        .then(res => {
          console.log('rrrrrrr', res);
          ExportExcel(res.fileBytes, "application/xls", res.fileName);
        }).catch(err => {
        this.$message({
          message: err.message,
          type: "warning"
        });
      }).finally(() => {
            this.tableLoading = false
      })
  }

  //切换
  toTab() {
    this.showType = !this.showType
    this.text = this.showType == true ? '切换图表' : '切换表格'
  }
  choseData() {
    if(!this.submit_time1) {
      this.$message.warning('请选择开始时间')
      return
    }
    if(!this.submit_time2) {
      this.$message.warning('请选择结束时间')
      return
    }
    this.getData()
    this.getDataEchart()
  }

  // 获取省份
  provinceList = []
  getProvince() {
    api
      .post('J22013')
      .then(res => {
        this.provinceList = res.list
      })
  }
  // 获取交易中心
  tradList = []
  getTrad(bool: any) {
    this.formInline.trading_center_code = []
    this.formInline.sending_organization_code = []

    if(!bool) {
      api
          .post('J22014', {parent_codes: this.formInline.province_code})
          .then(res => {
            this.tradList = res.list
          })
    }
  }
  removeTag(tag: any) {
    if(tag == 'trad') {
      api
              .post('J22014', {parent_codes: []})
              .then(res => {
                this.tradList = res.list
              })
    } else if(tag == 'organization') {
      api
              .post('J22015', {parent_codes: []})
              .then(res => {
                this.tradingCenterList = res.list
              })
    }

  }
  // 获取出函机构
  tradingCenterList = []
  getOrganization(bool: any) {
    if(!bool) {
      api
          .post('J22015', {parent_codes: this.formInline.trading_center_code})
          .then(res => {
            this.tradingCenterList = res.list
          })
    }
  }
  // 获取时间类型
  choseType(type: any) {
    this.type = type == 'month' ? 'year' : (type == 'day' ? 'month' : 'date')
    this.format = type == 'month' ? 'yyyy' : (type == 'day' ? 'yyyy-MM' : 'yyyy-MM-dd')
    this.valueformat = type == 'month' ? 'yyyy' : (type == 'day' ? 'yyyy-MM' : 'yyyy-MM-dd')

    this.submit_time1 = ''
    this.submit_time2 = ''
    this.formInline.submit_time[0] = ''
    this.formInline.submit_time[1] = ''
  }

  tableData = []
  rightSpanMethod({row, column, rowIndex, columnIndex}){
    if (columnIndex == 0) {
      //合并相同的名字
      let nameSpan = this.getSpanNumber(this.tableData, "province_code"); //"province_name"是要合并的属性名
      return {
        rowspan: nameSpan[rowIndex],
        colspan: 1,
      };
    }

    //判断是否为第二列
    if (columnIndex == 1) {
      //合并相同的名字
      let nameSpan = this.getSpanNumber(this.tableData, "trading_center_name"); //"trading_center_name"是要合并的属性名
      return {
        rowspan: nameSpan[rowIndex],
        colspan: 1,
      };
    }
  }

  getSummaries(param) {
    const { columns, data } = param;
    // console.log('columns, data', columns, data)
    const sums = [];
    columns.forEach((column, index) => {

      if (index === 0) {
        sums[index] = '总计';
        return;
      }
      const values = data.map(item => Number(item[column.property]));
      if (!values.every(value => isNaN(value))) {
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
            // return new BigNumber(prev).plus(new BigNumber(curr));
          } else {
            return prev;
          }
        }, 0);
        sums[index] += '';
      } else {
        //N/A
        sums[index] = ' ';
      }
    });
    let a = []
    sums.forEach((item: any, index: any) => {
      if(index == 5) {
        if(item == 0) {
          item = '0.00'
        } else {
          item= Number(item).toFixed(2)
        }
      }
      a.push(item)
    })
    return a;
  }

  //获取要合并的行数,，需要在上面方法中调用
  getSpanNumber(data, prop) {
    //data要处理的数组，prop要合并的属性，比如name
    //数组的长度，有时候后台可能返回个null而不是[]
    let length = Array.isArray(data) ? data.length : 0;
    if (length > 0) {
      //用于标识位置
      let position = 0;
      //用于对比的数据
      let temp = data[0][prop];
      // console.log('temp', temp);
      //要返回的结果
      let result = [1];

      for (let i = 1; i < length; i++) {
        //data[i][prop] == temp
        if (data[i][prop] == temp) {
          //标识位置的数据加一
          result[position] += 1;
          //当前位置添0
          result[i] = 0;
        } else {
          //不相同时，修改标识位置，该位置设为1，修改对比值
          position = i;
          result[i] = 1;
          temp = data[i][prop];
        }
      }
      //返回结果
      return result;
    } else {
      return [0];
    }
  }

  updated() {
    if(this.baobiaoDialog) {
      this.$nextTick(() => {
        (this.$refs.table as any).doLayout()
      })
    }

  }

  getData() {
    this.tableLoading = true
    this.formInline.submit_time[0] = this.submit_time1
    this.formInline.submit_time[1] = this.submit_time2
    api
      .post('J22012', this.formInline)
      .then(res => {
        this.tableData = res.list
      }).finally(() => {
        this.tableLoading = false
    })
  }

  showE = true
  getDataEchart() {
    let time = []
    time[0] = this.submit_time1
    time[1] = this.submit_time2
    api
            .post('J22011', {time: time, province_code: this.formInline.province_code, sending_organization_code: this.formInline.sending_organization_code, trading_center_code: this.formInline.trading_center_code, show_empty: this.formInline.show_empty })
            .then(data => {
              // 折线柱状
              this.showE = false
              this.$nextTick(() => {
                this.showE = true

              })
              setTimeout(() => {
                this.drawLine_lineBar2(this.$refs.chartD, data.list);
              })

            })
  }

  clearTime() {
    if (this.timeMap) {
      clearInterval(this.timeMap);
    }
    if (this.timePie) {
      clearInterval(this.timePie);
    }
    if (this.timeUpdate) {
      clearTimeout(this.timeUpdate);
    }
    if (this.timeInit) {
      clearTimeout(this.timeInit);
    }
    if (this.timeMapOut) {
      clearTimeout(this.timeMapOut);
    }
  }

  changeSystem() {
    if (this.system === "17") {
      this.system = "19";
    } else {
      this.system = "17";
    }
    this.clearTime();
    this.loading = 0;
    sessionStorage.setItem("system", this.system);
    this.LoadData();
  }

  // 订单滚动
  orderNum = ["0", "0", "0", "0", "0", "0", "0"];
  OrderNumber = 823123;
  setNumberTransform() {
    let numberItems = this.$refs.numberItem; // 拿到数字的ref，计算元素数量
    let numberArr: any = this.orderNum.filter((item: any) => !isNaN(item));
    // 结合CSS 对数字字符进行滚动,显示订单数量
    for (let index = 0; index < (numberItems as any).length; index++) {
      const elem = numberItems[index];
      elem.style.transform = `translate(-50%, -${numberArr[index] * 10}%)`;
    }
  }

  // 处理总订单数字
  toOrderNum(num) {
    num = num.toString();
    // 把订单数变成字符串
    if (num.length < 7) {
      num = "0" + num; // 如未满八位数，添加"0"补位
      this.toOrderNum(num); // 递归添加"0"补位
    } else if (num.length === 7) {
      // 订单数中加入逗号
      this.orderNum = num.split(""); // 将其便变成数据，渲染至滚动数组
    }
  }

  // 统计
  count = {
    jinriMoeny: {
      startVal: 0,
      endVal: 0,
    },
    MonthMoeny: {
      startVal: 0,
      endVal: 0,
    },
    jinriOrder: {
      startVal: 0,
      endVal: 0,
    },
    allMoeny: {
      startVal: 0,
      endVal: 0,
    },
    allDBMoeny: {
      startVal: 0,
      endVal: 0,
    },
    allComony: {
      startVal: 0,
      endVal: 0,
    },
  };

  loading = 0;

  timeUpdate = null;
  // 统计更新
  updateCount(isOne = false) {
    api
      .post("A" + this.system + "001")
      .then((data) => {
        this.count.jinriMoeny.startVal = this.count.jinriMoeny.endVal;
        this.count.jinriMoeny.endVal = data.charge * 1;

        if (this.system === "19") {
          this.count.MonthMoeny.startVal = this.count.MonthMoeny.endVal;
          this.count.MonthMoeny.endVal = data.month_charge * 1;
        }

        this.count.jinriOrder.startVal = this.count.jinriOrder.endVal;
        this.count.jinriOrder.endVal = data.order_quantity * 1;

        this.count.allMoeny.startVal = this.count.allMoeny.endVal;
        this.count.allMoeny.endVal = data.total_charge * 1;

        this.count.allDBMoeny.startVal = this.count.allDBMoeny.endVal;
        this.count.allDBMoeny.endVal = data.total_amount * 1;

        this.count.allComony.startVal = this.count.allComony.endVal;
        this.count.allComony.endVal = data.customer_count * 1;

        // 新闻资讯
        api
          .post("A" + this.system + "003")
          .then((data) => {
            this.newList = data;
          })
          .finally(() => {
            if (isOne) {
              this.loading++;
            }
          });
      })
      .catch((err) => {
        if (err.errcode === 10006) {
          this.noAuth = true;
        }
      })
      .finally(() => {
        if (isOne) {
          this.loading++;
        }
        this.timeUpdate = setTimeout(() => {
          this.updateCount();
        }, 10000);
      });
  }

  created() {
    this.getTime();
    this.getProvince()
    this.getTrad(false)
    this.getOrganization(false)
  }

  changeMapData() {
    return api.post("A" + this.system + "004");
  }

  audio = null;
  isAudio = false;

  timeInit = null;
  InitData(init = true) {
    api
      .post("A" + this.system + "002")
      .then((data) => {
        if (this.timePie) {
          clearInterval(this.timePie);
        }
        // 保费收入占比
        this.drawLine_pie(this.$refs.chart1, data);
      })
      .catch((err) => {
        if (err.errcode === 10006) {
          this.noAuth = true;
        }
      })
      .finally(() => {
        if (init) {
          this.loading++;
        }
      });

    api
      .post("A" + this.system + "007")
      .then((data) => {
        // 交易中心订单排行
        this.drawLine_bar(this.$refs.chart2, data);
      })
      .catch((err) => {
        if (err.errcode === 10006) {
          this.noAuth = true;
        }
      })
      .finally(() => {
        if (init) {
          this.loading++;
        }
      });

    // 折线图
    api
      .post("A" + this.system + "006")
      .then((data) => {
        this.drawLine_line(this.$refs.chart4, data);
      })
      .catch((err) => {
        if (err.errcode === 10006) {
          this.noAuth = true;
        }
      })
      .finally(() => {
        if (init) {
          this.loading++;
        }
      });
    api
      .post("A" + this.system + "008")
      .then((data) => {
        console.log('drawLine_lineBar', data);
        // 折线柱状
        this.drawLine_lineBar(this.$refs.chart5, data);
      })
      .catch((err) => {
        if (err.errcode === 10006) {
          this.noAuth = true;
        }
      })
      .finally(() => {
        if (init) {
          this.loading++;
        }
      });
    // 折线图 活跃客户数量
    api
      .post("A" + this.system + "009")
      .then((data) => {
        this.drawLine_line2(this.$refs.chart6, data);
      })
      .catch((err) => {
        if (err.errcode === 10006) {
          this.noAuth = true;
        }
      })
      .finally(() => {
        if (init) {
          this.loading++;
        }
      });

    // 10分钟一下
    this.timeInit = setTimeout(() => {
      this.InitData(false);
    }, 1000 * 60 * 10);
  }

  // 获取今年数据
  orderToYear = 0;
  getToYearData(){
    // 由于接口编号规则不同，则由ststem做判断
    api.post(this.system==='17'?"J22021":"J22022").then(data=>{
      this.orderToYear = data
    });
  }

  LoadData() {
    // 实时统计
    this.updateCount(true);
    this.changeMapData()
      .then((data) => {
        this.OrderNumber = data;
        this.getToYearData();
        this.toOrderNum(this.OrderNumber);
        this.setNumberTransform();
      })
      .catch((err) => {
        if (err.errcode === 10006) {
          this.noAuth = true;
        }
      })
      .finally(() => {
        this.loading++;
      });

    api
      .post("A" + this.system + "005")
      .then((data) => {
        data.push({ name: "云南", value: ["102.977396", "24.707886"] });
        data.push({ name: "陕西", value: ["108.931986", "34.326174"] });
        data.push({ name: "海南", value: ["110.228373", "20.046614"] });
        data.push({ name: "柳州", value: ["109.43049", "24.302708"] });
        data.push({ name: "梧州", value: ["111.281687", "23.453833"] });
        data.push({ name: "信阳罗山县", value: ["114.519564", "32.195957"] });
        data.push({ name: "信阳息县", value: ["114.737917", "32.337625"] });
        data.push({ name: "信阳淮滨县", value: ["115.420443", "32.468645"] });
        data.push({ name: "信阳新县", value: ["114.880053", "31.641111"] });
        data.push({ name: "信阳光山县", value: ["114.920565", "32.002841"] });
        data.push({ name: "元氏县", value: ["114.529863", "37.76312"] });
        data.push({ name: "高邑县", value: ["114.612947", "37.613964"] });
        data.push({ name: "行唐县", value: ["114.550806", "38.435309"] });
        data.push({ name: "赞皇县", value: ["114.387041", "37.664804"] });
        data.push({ name: "馆陶县", value: ["115.282427", "36.547437"] });
        data.push({ name: "隆尧县", value: ["114.773107", "37.346966"] });
        data.push({ name: "新河县", value: ["115.251013", "37.519534"] });
        data.push({ name: "威县", value: ["115.266806", "36.974586"] });
        data.push({ name: "宁晋县", value: ["114.939276", "37.622803"] });
        data.push({ name: "阿勒泰", value: ["88.089660", "47.751583"] });

        // 地图
        this.drawLine_map(this.$refs.chart3, data);
      })
      .catch((err) => {
        if (err.errcode === 10006) {
          this.noAuth = true;
        }
      })
      .finally(() => {
        this.loading++;
      });
    this.InitData();
  }

  mounted() {
    this.audio = document.getElementById("jinbiAudio");
    this.LoadData();
  }

  // 柱状折线
  myChartLineBar: any;
  async drawLine_lineBar(item: any, list: any) {
    let x_name = [];
    let s_data_first = [];
    let s_data_two = [];
    let s_data_rate = [];
    list.forEach((item: any) => {
      x_name.push(item.time);
      s_data_first.push(item.plan);
      s_data_two.push(item.letter);
      s_data_rate.push(item.rate);
    });
    this.myChartLineBar = echarts.init(item as HTMLDivElement);
    let _myChart = this.myChartLineBar;
    let _option = {
      legend: {
        data: ["预售单", "已出函", "成单率"],
        textStyle: {
          color: "rgba(170, 221, 255, 0.8)",
        },
        bottom: 20,
        selectedMode: false,
      },
      xAxis: {
        type: "category",
        data: x_name,
        splitLine: {
          show: false,
          lineStyle: {
            color: "#214890",
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: "#214890",
          },
        },
        axisLabel: {
          color: "rgba(170, 221, 255, 0.8)",
          interval: 0,
          fontSize: 10,
        },
        silent: true,
      },
      yAxis: [
        {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#2c3977",
            },
          },
          axisLabel: {
            color: "rgba(170, 221, 255, 0.8)",
            fontSize: 10,
          },
          axisLine: {
            lineStyle: {
              color: "#214890",
            },
            show: true,
          },
          minInterval: 1,
          silent: true,
        },
        {
          type: "value",
          show: true,
          splitLine: {
            show: true,
            lineStyle: {
              color: "#2c3977",
            },
          },
          axisLabel: {
            color: "rgba(170, 221, 255, 0.8)",
            formatter: "{value}%",
            fontSize: 10,
          },
          silent: true,
        },
      ],
      series: [
        {
          type: "bar",
          name: "预售单",
          data: s_data_first,
          itemStyle: {
            color: "#00b4ff",
          },
        },
        {
          type: "bar",
          name: "已出函",
          data: s_data_two,
          itemStyle: {
            color: "#ff9c00",
          },
        },
        {
          type: "line",
          name: "成单率",
          yAxisIndex: 1,
          lineStyle: {
            color: "#afff48",
          },
          itemStyle: {
            color: "#afff48",
          },
          data: s_data_rate,
        },
      ],
    };
    _myChart.setOption(_option);
    _myChart.on("click", (params) => {
      this.clickLabel(item, params);
    });
  }

  // 柱状折线
  myChartLineBar2: any;
  async drawLine_lineBar2(item: any, list: any) {
    let x_name = [];
    let s_data_first = [];
    let s_data_two = [];
    let s_data_rate = [];
    list.forEach((item: any) => {
      x_name.push(item.submit_time);
      s_data_first.push(item.letter_count);
      s_data_two.push(item.refund_count);
      s_data_rate.push(item.income);
    });



    this.myChartLineBar2 = echarts.init(item as HTMLDivElement);
    let _myChart = this.myChartLineBar2;
    _myChart.showLoading({
      text: 'loading',
      color: '#c23531',
      textColor: '#000',
      maskColor: 'rgba(255, 255, 255, 0.2)',
      zlevel: 0
    })


    var start = list.length - 12
    var end = list.length - 1

    let _option = {
      dataZoom: {
        type: 'inside',// 内置于坐标系中
        // start: 6,
        // end: 30,
        startValue: 0,  // 重点在这   -- 开始的值
        endValue: list.length > 1 ? 11 : 0,   // 重点在这   -- 结束的值
        xAxisIndex: [0],
        zoomOnMouseWheel: false,  // 关闭滚轮缩放
        moveOnMouseWheel: true, // 开启滚轮平移
        moveOnMouseMove: true  // 鼠标移动能触发数据窗口平移
      },
      tooltip : {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      legend: {
        data: ["出函数", "退保数", "保费收入（万元）"],
        textStyle: {
          color: "rgba(170, 221, 255, 0.8)",
        },
        top: 20,
        selectedMode: true,
      },
      xAxis: {
        type: "category",
        data: x_name,
        splitLine: {
          show: false,
          lineStyle: {
            color: "#214890",
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: "#214890",
          },
        },
        axisLabel: {
          color: "rgba(170, 221, 255, 0.8)",
          // interval: 0,
          rotate: 30,
          fontSize: 10,
          showMaxLabel: true,       // 显示最大刻度
          showMinLabel: true,      // 显示最小刻度
          hideOverlap: true,    // 隐藏互相遮挡的文本标签
        },
        silent: true,
      },
      yAxis: [
        {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#2c3977",
            },
          },
          axisLabel: {
            color: "rgba(170, 221, 255, 0.8)",
            fontSize: 10,
          },
          axisLine: {
            lineStyle: {
              color: "#214890",
            },
            show: true,
          },
          minInterval: 1,
          silent: true,
        },
        {
          type: "value",
          show: true,
          splitLine: {
            show: true,
            lineStyle: {
              color: "#2c3977",
            },
          },
          axisLabel: {
            color: "rgba(170, 221, 255, 0.8)",
            formatter: "{value}",
            fontSize: 10,
          },
          silent: true,
        },
      ],
      series: [
        {
          type: "bar",
          name: "出函数",
          data: s_data_first,
          itemStyle: {
            color: "rgb(217, 117, 89)",
          },
        },
        {
          type: "bar",
          name: "退保数",
          data: s_data_two,
          itemStyle: {
            color: "rgb(228, 196, 119)",
          },
        },
        {
          type: "line",
          name: "保费收入（万元）",
          yAxisIndex: 1,
          lineStyle: {
            color: "rgb(218, 166, 123)",
          },
          itemStyle: {
            color: "rgb(218, 166, 123)",
          },
          data: s_data_rate,
        },
      ],
    };
    if(list.length <= 12) {
      delete _option.dataZoom;
    }
    _myChart.hideLoading()
    _myChart.setOption(_option);
    // _myChart.on("click", (params) => {
    //   this.clickLabel(item, params);
    // });
  }

  clickLabel(item: any, params: any) {
    let removeLabel = item.getElementsByClassName("myLabel");
    for (let index = 0; index < removeLabel.length; index++) {
      removeLabel[index].remove();
    }
    var myLabel = document.createElement("div");
    myLabel.setAttribute("class", "myLabel");
    myLabel.setAttribute(
      "style",
      "position: absolute;top:" +
        (params.event.offsetY - 25) +
        "px;left:" +
        params.event.offsetX +
        "px;transform: translate(-50%, 0);  "
    );
    if (params.componentSubType === "bar") {
      myLabel.setAttribute(
        "style",
        "position: absolute;top:" +
          (params.event.offsetY - 25) +
          "px;left:" +
          params.event.offsetX +
          "px;transform: translate(-50%, 0);color:#FFF;"
      );
    }
    myLabel.innerHTML = params.value;
    item.appendChild(myLabel);
    setTimeout(() => {
      myLabel.remove();
    }, 5000);
  }

  // 折线图 活跃客户数量
  myChartLine2: any;
  async drawLine_line2(item: any, list: any) {
    let x_name = [];
    let s_data = [];
    list.forEach((item: any) => {
      x_name.push(item.time);
      s_data.push(item.liveness);
    });
    this.myChartLine2 = echarts.init(item as HTMLDivElement);
    let _myChart = this.myChartLine2;
    let _option = {
      xAxis: {
        type: "category",
        data: x_name,
        //设置网格线颜色
        splitLine: {
          show: false,
          lineStyle: {
            color: "#214890",
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: "#214890",
          },
        },
        axisLabel: {
          color: "rgba(170, 221, 255, 0.8)",
          interval: 0,
          fontSize: 10,
        },
        silent: true,
        boundaryGap: false,
      },
      yAxis: {
        type: "value",
        minInterval: 1,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#2c3977",
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#214890",
          },
        },
        axisLabel: {
          color: "rgba(170, 221, 255, 0.8)",
          fontSize: 10,
        },
        silent: true,
      },
      series: [
        {
          data: s_data,
          type: "line",
          symbol:
            "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDAyIDc5LjE2NDM1MiwgMjAyMC8wMS8zMC0xNTo1MDozOCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjEgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkQ2MjBBRDVCRDFBNTExRUNBM0QxQjQwRUVDRjc3MjM5IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkQ2MjBBRDVDRDFBNTExRUNBM0QxQjQwRUVDRjc3MjM5Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RDYyMEFENTlEMUE1MTFFQ0EzRDFCNDBFRUNGNzcyMzkiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RDYyMEFENUFEMUE1MTFFQ0EzRDFCNDBFRUNGNzcyMzkiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6ob4o7AAAAEElEQVR42mL4//8/A0CAAQAI/AL+26JNFgAAAABJRU5ErkJggg==",
          symbolSize: 30,
          lineStyle: {
            color: "#ff9c00",
          },
          itemStyle: {
            color: "#ff9c00",
          },
          areaStyle: {
            color: "#2c2a43",
          },
        },
      ],
    };
    _myChart.setOption(_option);
    _myChart.on("click", (params) => {
      this.clickLabel(item, params);
    });

    // // 动态更新
    // setInterval(() => {
    //   s_data = [];
    //   list.forEach((item: any) => {
    //     item.value += Math.round(Math.random() * 100);
    //     s_data.push(item.value);
    //   });
    //   _myChart.setOption({
    //     series: [
    //       {
    //         data: s_data,
    //       },
    //     ],
    //   });
    // }, 5000);
  }

  // 折线图
  myChartLine: any;
  async drawLine_line(item: any, list: any) {
    let x_name = [];
    let s_data = [];
    list.forEach((item: any) => {
      x_name.push(item.time);
      s_data.push(item.letter);
    });
    this.myChartLine = echarts.init(item as HTMLDivElement);
    let _myChart = this.myChartLine;
    let _option = {
      xAxis: {
        type: "category",
        data: x_name,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#214890",
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: "#214890",
          },
        },
        axisLabel: {
          color: "rgba(170, 221, 255, 0.8)",
          interval: 0,
          fontSize: 10,
        },
        silent: true,
      },
      yAxis: {
        type: "value",
        minInterval: 1,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#214890",
          },
        },
        axisLabel: {
          color: "rgba(170, 221, 255, 0.8)",
          fontSize: 10,
        },
        silent: true,
      },
      series: [
        {
          data: s_data,
          type: "line",
          silent: false,
          smooth: true,
          symbol:
            "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAACCElEQVQ4jY2UTUgUYRjHf85+6GJYRB/oaloIOUrTx6mDJV06RCWd5pDUlregICKQDtYhqE7bIcJLdAiJgaIuBRUVRbjQIXIwJkItRPtgM2upzTXajWfx3d5ZHdY/zMDzfx5+7zzPvO9bFd10kgpqAaLAGPA3qDQsL8f1fKZtmTuBQ8ABYKWWeghcBR45rpfV6jHKAI22Zd4AngG9ZRDRbuAuMGxb5nY9YegQ4B7QoxesbvhD/bo5qnxL0gqkbMvc5WttXucBSwXdR6fp6v5OfP1cMc79NnBTtVw508BstkS9bVvmFmBCDXsH8Fxlj1+conNPZtGhTo1Xc+5IM5mZkLIuAX0KfVC5+xLTgRBRfEOOU8lJ3UrA/873Krdr/49AiFLbtixtW0s/bS3QKaDlspA4sWV5mlpzFUGiRn9dh4BKzRpGYUkQUSjkC8MC+gYU+/mVCfH1U2RJoM8TUT0cVTO6r5ynd1ZUhHx4W8PwUK0K88ALBbqm3FsDq3j3OhYImUmHGThbr1tyZH4WQY7rPQaeqEx/ooXUg7oFkNGRGBeONfHeq9Ht65Tt7BPAEFBXyMPl03EGk2to3jhLtLrAl8kIYyMLvvSw43qv5NCWQI7rvbEtU3b4TaBdvPTHSPEJUI/jeoMq5zuKjuu5wOb53fpyEUAaSEqNDhFVutjkUpNDKUMZD4AD8A9X/Izkt6ogGQAAAABJRU5ErkJggg==",
          symbolSize: 16,
          lineStyle: {
            color: "#afff48",
          },
          itemStyle: {
            color: "#afff48",
          },
        },
      ],
    };
    _myChart.setOption(_option);
    _myChart.on("click", (params) => {
      this.clickLabel(item, params);
    });

    // // 动态更新
    // setInterval(() => {
    //   s_data = [];
    //   list.forEach((item: any) => {
    //     item.value += Math.round(Math.random() * 100);
    //     s_data.push(item.value);
    //   });
    //   _myChart.setOption({
    //     series: [
    //       {
    //         data: s_data,
    //       },
    //     ],
    //   });
    // }, 5000);
  }

  timeMap = null;
  timeMapOut = null;

  // 地图构造
  myChartMap: any;
  async drawLine_map(item: any, list: any) {
    const res: any = await axios.get("/chinaMap.json");
    echarts.registerMap("china", res.data);
    this.myChartMap = echarts.init(item as HTMLDivElement, "none", {
      renderer: "svg",
    });
    let _myChart = this.myChartMap;
    const gb = "image://" + require("../assets/latlon.png");
    const jb = "image://" + require("../assets/jb.gif");
    const jb_dh = "image://" + require("../assets/jb_dh.png");

    const effect = {
      type: "effectScatter",
      coordinateSystem: "geo",
      animation: false, //坐标点是否显示动画
      symbol: gb,
      symbolSize: 14,
      data: list.map((item) => {
        return {
          name: item.name,
          value: item.value,
          itemStyle: {
            normal: {
              color: "#ffff00",
            },
          },
          label: {
            show: false,
            color: "rgb(255, 255, 0)",
            formatter: "{b}",
            offset: [20, 0],
            fontSize: "10",
          },
        };
      }),
    };
    let _option = {
      geo: {
        map: "china",
        zoom: 1.68,
        top: "31.5%",

        label: {
          show: true,
          fontSize: "8",
          color: "#28a7e1",
        },
        itemStyle: {
          borderColor: "#1085AC",
          areaColor: "#1a439a",
        },
        show: false,
        silent: true,
      },
      tooltip: {
        backgroundColor: "#0c1a4f",
        borderColor: "#2ca3ff",
        textStyle: {
          color: "#aaddff",
        },
        position: "right",
        className: "echarts-tooltip",
        triggerOn: "none",
        padding: [5, 10],
        fontSize: 12,
        formatter: function (params) {
          return params.name;
        },
      },
      series: [effect],
    };
    _myChart.setOption(_option);

    var index = 0;
    this.timeMap = setInterval(function () {
      _myChart.dispatchAction({
        type: "showTip",
        seriesIndex: 0,
        dataIndex: index,
      });
      index++;
      if (index > list.length) {
        index = 0;
      }
    }, 3000);

    let isOne = true;
    let gold = [];
    let changeMap = () => {
      let _kz = {};
      if (isOne) {
        isOne = false;
        api
          .post("A" + this.system + "010", { count: this.OrderNumber })
          .then((data) => {
            gold = data;
            _kz = {
              series: [
                effect,
                {
                  type: "scatter",
                  coordinateSystem: "geo",
                  symbol: jb,
                  symbolSize: 25,
                  data: gold.map((item) => {
                    return {
                      name: "1",
                      value: item,
                      itemStyle: {
                        normal: {
                          color: "#ffff00",
                          opacity: 1,
                        },
                      },
                    };
                  }),
                },
              ],
            };
            _myChart.setOption(_kz);
          }).finally(()=>{
            if (gold.length > 0) {
              this.timeMapOut = setTimeout(() => {
                changeMap();
              }, 3000);
            } else {
              isOne = true;
              this.timeMapOut = setTimeout(() => {
                changeMap();
              }, 5000);
            }
          });
      } else {
        isOne = true;
        this.changeMapData().then((data) => {
          _kz = {
            series: [
              effect,
              {
                type: "lines",
                symbolSize: 10,
                effect: {
                  show: true,
                  period: 1,
                  trailLength: 0,
                  symbol: jb_dh,
                  symbolSize: 25,
                  loop: false,
                },
                lineStyle: {
                  color: "#2d9df1", //航线的颜色
                  width: 1,
                  opacity: 0,
                  curveness: 0.2, //  弯曲程度
                },
                data: gold.map((item) => {
                  return {
                    fromName: "1",
                    toName: "金币",
                    coords: [item, [87.68, 51.77]],
                    value: [87.68, 51.77],
                  };
                }),
              },
            ],
          };
          _myChart.setOption(_kz);

          //更新计数
          setTimeout(() => {
            //播放音效
            if (this.isAudio) {
              this.audio.play();
            }
            this.OrderNumber = data;
            this.getToYearData();
            this.toOrderNum(this.OrderNumber);
            this.setNumberTransform();
          }, 1000);
        }).finally(()=>{
          this.timeMapOut = setTimeout(() => {
            changeMap();
          }, 5000);
        });
      }
    };
    this.timeMapOut = setTimeout(() => {
      changeMap();
    }, 5000);
  }

  // 无缝滚动
  newList = [];
  optionSetting = {
    step: 0.25, // 数值越大速度滚动越快
    limitMoveNum: 2, // 开始无缝滚动的数据量
    hoverStop: true, // 是否开启鼠标悬停stop
    direction: 1, // 0向下 1向上 2向左 3向右
    openWatch: true, // 开启数据实时监控刷新dom
    singleHeight: 30, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
    waitTime: 3000, // 单步运动停止的时间(默认值1000ms)
  };

  // 构建柱状
  myChartBar: any;
  drawLine_bar(item: any, list: any) {
    let _y: any = [];
    let _s: any = [];
    const colors = [
      { color: "#ff0000" },
      { color: "#ff9c00" },
      { color: "#ffd200" },
      { color: "#afff48" },
      { color: "#00b4ff" },
      { color: "#0066ff" },
    ];
    list.forEach((item: any, index: number) => {
      _y.unshift({
        value: item.org_name,
        textStyle: {
          color: "#81ACD2",
        },
      });
      _s.unshift({
        value: item.ratio,
        itemStyle: { color: colors[index].color },
      });
    });
    this.myChartBar = echarts.init(item as HTMLDivElement);
    let _myChart = this.myChartBar;
    let _option = {
      grid: {
        top: "20px",
        left: "50px",
        right: "50px",
        bottom: "20px",
        containLabel: true,
      },
      xAxis: {
        show: false,
      },
      yAxis: {
        type: "category",
        inverse: true,
        data: _y,
        axisLabel: {
          formatter: function (params: any) {
            if (params.length > 4) {
              return params.substr(0, 4);
            } else {
              return params;
            }
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: "#1C68DA",
          },
        },
        animationDuration: 300,
        animationDurationUpdate: 300,
      },
      series: [
        {
          realtimeSort: true,
          type: "bar",
          label: {
            show: true,
            position: "right",
            color: "#B9B4B4",
            formatter: "{c}",
          },
          barWidth: 10,
          data: _s,
        },
      ],
      animationDuration: 0,
      animationDurationUpdate: 2500,
      animationEasing: "linear",
      animationEasingUpdate: "linear",
    };
    _myChart.setOption(_option);

    // 动态更新
    // setInterval(() => {
    //   for (var i = 0; i < list.length; ++i) {
    //     _s[i].value = Math.round(Math.random() * 200);
    //   }
    //   _myChart.setOption(_option);
    // }, 5000);
  }

  timePie = null;
  // 构建饼状
  myChartPie: any;
  drawLine_pie(item: any, list: any) {
    let _data: any = [];
    let colors = [
      { color: "#ff0000" },
      { color: "#ff9c00" },
      { color: "#ffd200" },
      { color: "#00b4ff" },
      { color: "#0066ff" },
    ];
    list.forEach((item: any, index: number) => {
      _data.unshift({
        value: item.ratio,
        name: item.scope,
        itemStyle: { color: colors[index].color },
      });
    });
    this.myChartPie = echarts.init(item as HTMLDivElement);
    let _myChart = this.myChartPie;
    let _option = {
      top: {
        bottom: "20px",
        containLabel: true,
      },
      legend: {
        bottom: -5,
        itemWidth: 10,
        itemHeight: 10,
        selectedMode: false,
        textStyle: {
          color: "#6381AE",
          fontSize: "12px",
        },
      },
      series: [
        {
          name: "保费收入占比",
          type: "pie",
          radius: ["45%", "68%"],
          height: "98%",
          labelLine: {
            length2: 5,
          },
          label: {
            color: "auto",
            formatter: "{d}%\n",
          },
          labelLayout: function (params) {
            const isLeft = params.labelRect.x < _myChart.getWidth() / 2;
            const points = params.labelLinePoints;
            // Update the end point.
            points[2][0] = isLeft
              ? params.labelRect.x
              : params.labelRect.x + params.labelRect.width;
            return {
              labelLinePoints: points,
            };
          },
          emphasis: {
            label: {
              show: true,
              fontSize: "13px",
            },
          },
          data: _data,
        },
      ],
    };
    _myChart.setOption(_option);

    // 动态更新
    let currentIndex = -1;
    this.timePie = setInterval(() => {
      var datalen = list.length;
      // 注释的地方 表示是否高亮
      _myChart.dispatchAction({
        type: "downplay",
        seriesIndex: 0,
        dataIndex: currentIndex,
      });
      currentIndex = (currentIndex + 1) % datalen;
      _myChart.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: currentIndex,
      });
      _myChart.dispatchAction({
        type: "showTip",
        seriesIndex: 0,
        dataIndex: currentIndex,
      });
      _myChart.setOption(_option);
    }, 5000);
  }

  // 时间计时
  getTime() {
    this.time = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
    setTimeout(() => {
      this.getTime();
    }, 1000);
  }
}
