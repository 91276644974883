// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/number.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "[data-v-68003243] .echarts-tooltip{left:-.781vw!important;z-index:99!important}.box-item[data-v-68003243]{position:relative;line-height:2.604vw;writing-mode:vertical-lr;text-orientation:upright;-moz-user-select:none;-webkit-user-select:none;-ms-user-select:none;-khtml-user-select:none;user-select:none}.box-item[data-v-68003243],.number-item[data-v-68003243]{height:2.604vw;list-style:none}.number-item[data-v-68003243]{width:3.021vw;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:3.438vw 3.438vw;overflow:hidden}.number-item>span[data-v-68003243]{position:relative;display:inline-block;width:100%;height:100%;writing-mode:vertical-rl;text-orientation:upright;overflow:hidden}.number-item>span>i[data-v-68003243]{font-style:normal;position:absolute;top:.104vw;left:50%;transform:translate(-50%);transition:transform 1s ease-in-out;letter-spacing:.521vw}.number-item[data-v-68003243]:last-child{margin-right:0}", ""]);
// Exports
module.exports = exports;
